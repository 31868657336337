import { Link } from "react-router-dom";
import { Container } from "./Container";
import { Logo } from "./Logo";

export function Footer() {
  return (
    <footer className="flex-none py-12 bg-gray-100">
      <Container className="flex flex-col items-center justify-between md:flex-row">
        <Link
          className="flex items-center gap-3 mb-6 md:mb-0"
          target="_blank"
          to="https://gamberadynamics.com/"
        >
          <div className="font-semibold">Powered by</div>
          <Logo className="h-12 w-auto text-slate-900" />
        </Link>
        <div className="font-semibold text-sm text-slate-900 flex flex-col gap-3 text-center mb-6 md:mb-0 md:flex-row md:gap-6">
          <Link
            target="_blank"
            to="document/privacy-policy"
            className="hover:text-blue-500"
          >
            Privacy policy
          </Link>
          <Link
            target="_blank"
            to="document/terms-and-conditions"
            className="hover:text-blue-500"
          >
            Terms & Conditions
          </Link>
          <Link
            target="_blank"
            to="document/cookie-policy"
            className="hover:text-blue-500"
          >
            Cookie policy
          </Link>
        </div>
        <p className="text-base text-slate-500 text-center md:text-right">
          &copy; {new Date().getFullYear()} Gambera Dynamics. All rights
          reserved.
        </p>
      </Container>
    </footer>
  );
}
