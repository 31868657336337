import React, { useState, useEffect } from "react";
import BlockContent from "@sanity/block-content-to-react";
import { sanityClient } from "./lib/Sanity";
import ContentRenderer from "./ContentRenderer";
import { useParams } from "react-router-dom";

const serializers = {
  types: {
    block: (props) => {
      const { style = "normal" } = props.node;

      if (/^h\d/.test(style)) {
        const level = style.replace(/[^\d]/g, "");
        return React.createElement(
          `h${level}`,
          { className: `text-${4 - level}xl font-bold my-${level}` },
          props.children
        );
      }

      if (style === "blockquote") {
        return (
          <blockquote className="border-l-4 border-gray-300 pl-4 italic my-4">
            {props.children}
          </blockquote>
        );
      }

      return <p className="my-2">{props.children}</p>;
    },
    list: ({ children }) => <ul className="list-disc pl-5 my-2">{children}</ul>,
    listItem: ({ children }) => <li className="my-1">{children}</li>,
  },
  marks: {
    link: ({ mark, children }) => {
      const { href } = mark;
      const target = href.startsWith("http") ? "_blank" : undefined;
      return (
        <a
          href={href}
          target={target}
          rel={target === "_blank" ? "noopener noreferrer" : undefined}
          className="text-blue-600 hover:underline"
        >
          {children}
        </a>
      );
    },
    strong: ({ children }) => (
      <strong className="font-semibold">{children}</strong>
    ),
    em: ({ children }) => <em className="italic">{children}</em>,
  },
};

const TermsAndConditions = () => {
  const [data, setData] = useState(null);
  const [activeSection, setActiveSection] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  useEffect(() => {
    const fetchTermsAndConditions = async () => {
      try {
        const result = await sanityClient.fetch(
          `
          *[_type == "termsAndConditions" && slug.current == $slug][0] {
            title,
            version,
            effectiveDate,
            sections[] {
              sectionTitle,
              content
            }
          }
        `,
          { slug }
        );
        setData(result);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching Terms and Conditions:", err);
        setError(err);
        setIsLoading(false);
      }
    };

    fetchTermsAndConditions();
  }, []);

  if (isLoading)
    return <div className="text-gray-700 text-center">Loading...</div>;
  if (error)
    return (
      <div className="text-red-500 text-center">
        Error loading Terms and Conditions
      </div>
    );
  if (!data) return null;

  return (
    <div className="bg-gray-100 min-h-screen text-gray-800 p-8">
      <div className="max-w-6xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6">
          <h1 className="text-3xl font-semibold mb-4">{data.title}</h1>
          <div className="mb-6 text-gray-600">
            <p>Version: {data.version}</p>
            <p>
              Effective Date:{" "}
              {new Date(data.effectiveDate).toLocaleDateString()}
            </p>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/3 pr-4 mb-6 md:mb-0">
              <ul className="space-y-2">
                {data.sections.map((section, index) => (
                  <li
                    key={index}
                    className={`cursor-pointer p-2 rounded transition-all duration-200 ${
                      activeSection === index
                        ? "bg-gray-200 font-medium"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={() => setActiveSection(index)}
                  >
                    {section.sectionTitle}
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full md:w-2/3 md:pl-4 md:border-l border-gray-300">
              <h2 className="text-xl font-medium mb-4">
                {data.sections[activeSection].sectionTitle}
              </h2>
              <div className="prose prose-sm">
                {/* <BlockContent
                  blocks={data.sections[activeSection].content}
                  serializers={serializers}
                  projectId={sanityClient.config().projectId}
                  dataset={sanityClient.config().dataset}
                /> */}
                <ContentRenderer
                  content={data.sections[activeSection].content}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bg-gray-50 p-4 text-center">
          <button className="bg-blue-600 text-white font-medium py-2 px-4 rounded hover:bg-blue-700 transition-colors duration-200">
            Back
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default TermsAndConditions;
