import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Mic, Video, Image, Volume2 } from "lucide-react";
import { BackgroundImage } from "./BackgroundImage";
import { Container } from "./Container";
import AppContext from "../AppContext";
import Login from "./Login";
import Signup from "./SignUp";

const ProductCard = ({
  product,
  index,
  setShowLoginModal,
  setRedirectPath,
}) => {
  const navigate = useNavigate();
  const authCtx = useContext(AppContext);
  const isLoggedIn = authCtx.isLoggedIn;
  // const Icon = product.icon;
  const isTryNow = index < 2;
  const buttonText = isTryNow ? "Try Now" : "Coming Soon";

  const handleClick = () => {
    if (isTryNow) {
      if (isLoggedIn) {
        navigate(product.path);
      } else {
        setRedirectPath(product.path);
        setShowLoginModal(true);
      }
    }
  };

  return (
    <div
      className={`relative overflow-hidden bg-white shadow-xl shadow-blue-900/5 backdrop-blur rounded-xl transition-all duration-300 hover:shadow-2xl hover:-translate-y-1 ${
        isTryNow ? "cursor-pointer" : "cursor-default"
      } group`}
      onClick={handleClick}
      aria-disabled={!isTryNow}
    >
      <div
        className={`absolute inset-0 bg-gradient-to-br ${product.color} opacity-75 group-hover:opacity-100 transition-opacity duration-300`}
      ></div>
      <div className="relative p-8">
        <div className="flex items-center mb-4">
          <div className="p-3 bg-white rounded-full mr-4">
            {/* <Icon className="w-8 h-8 text-blue-600" /> */}
            <img
              src={product.icon.asset.url} // Use the URL from the product.icon object
              alt={product.name} // Alt text for accessibility
              className="w-8 h-8 object-cover filter invert-[40%] sepia-[100%] saturate-[2000%] hue-rotate-[180deg] brightness-[90%] contrast-[100%]"
            />
          </div>
          <h4 className="text-2xl font-bold tracking-tight text-white">
            {product.name}
          </h4>
        </div>
        <p className="text-lg tracking-tight text-white/90">
          {product.description}
        </p>
        <div className="mt-6 flex justify-end">
          <span
            className={`inline-flex items-center rounded-full px-4 py-2 text-sm font-medium transition-all duration-300
            ${
              isTryNow
                ? "bg-white text-blue-600 from-blue-400 to-indigo-500 group-hover:bg-gradient-to-r group-hover:text-white group-hover:scale-105"
                : "bg-white/20 text-white"
            }`}
          >
            {buttonText}
            <svg
              className="ml-2 h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
};

export function AIProducts({ data }) {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [redirectPath, setRedirectPath] = useState(null);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const navigate = useNavigate();
  const authCtx = useContext(AppContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const handleLoginSuccess = () => {
    if (redirectPath) {
      navigate(redirectPath); // Navigate to the intended path after login
    }
    setShowLoginModal(false); // Close the login modal
  };

  return (
    <section
      id="models"
      aria-label="AI Products"
      className="py-16 sm:py-24 bg-white"
    >
      <BackgroundImage position="right" className="-bottom-32 -top-40" />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-4xl lg:pr-24">
          <h2 className="font-display text-4xl font-bold tracking-tighter text-blue-600 sm:text-5xl">
            {data.heading}
          </h2>
          <p className="mt-4 font-display text-2xl tracking-tight text-blue-900">
            {data.subheading}
          </p>
        </div>
        <div className="relative mt-14 sm:mt-24">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
            {data.models.map((product, index) => (
              <ProductCard
                key={index}
                product={product}
                index={index}
                setShowLoginModal={setShowLoginModal}
                setRedirectPath={setRedirectPath}
              />
            ))}
          </div>
          {!isLoggedIn && (
            <div className="flex gap-2 flex-col items-center justify-center mt-10 w-full">
              <button
                onClick={() => setShowSignupModal(true)}
                className="inline-flex items-center w-fit bg-blue-600  text-white rounded-full px-4 py-2 text-base font-semibold hover:bg-blue-300 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-400 active:text-gray-800/70 transition-colors duration-200"
              >
                Start Now
              </button>
              <span className="text-lg font-semibold w-fit text-blue-600 border-b-2 border-blue-600 pb-1">
                {data.message}
              </span>
            </div>
          )}
        </div>
      </Container>
      {showLoginModal && (
        <Login
          setShowLoginModal={setShowLoginModal}
          onLoginSuccess={handleLoginSuccess}
        />
      )}
      {showSignupModal && (
        <Signup
          setShowLoginModal={setShowSignupModal}
          onSignUpSuccess={() => {}}
        />
      )}
    </section>
  );
}
