import { Link } from "react-router-dom";
import { BackgroundImage } from "./BackgroundImage";
import { Container } from "./Container";
import { useEffect, useState } from "react";
import { sanityClient } from "./lib/Sanity";

export default function Articles({ data }) {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const result = await sanityClient.fetch(`
          *[_type == "article"] | order(publishedAt desc)[0..2]  {
            _id,
            title,
            slug,
            mainImage {
              asset -> {
                _id,
                url
              }
            },
            publishedAt,
            author -> {
              name
            },
            categories[] -> {
              title
            }
          }
        `);
        setArticles(result);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching articles:", err);
        setError(err);
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (isLoading) return <div className="text-center">Loading articles...</div>;
  return (
    <div id="articles" className="py-24 sm:py-28 relative isolate bg-white">
      <BackgroundImage position="left" />
      <Container className="relative">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {data.heading}
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              {data.subheading}
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {articles &&
              articles.map((article) => (
                <Link
                  to={`/article/${article.slug.current}`}
                  key={article._id}
                  className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80 group" // Added group class
                >
                  <img
                    alt=""
                    src={article.mainImage.asset.url}
                    className="absolute inset-0 -z-10 h-full w-full object-cover transition-transform duration-300 group-hover:scale-105" // Added transition and hover scale
                  />
                  <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                  <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                  <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                    By {article.author.name} on{" "}
                    {new Date(article.publishedAt).toLocaleDateString()}
                  </div>
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                    <a href={article.href}>
                      <span className="absolute inset-0" />
                      {article.title}
                    </a>
                  </h3>
                </Link>
              ))}
          </div>
          <div className="flex  justify-center mt-10 w-full">
            <Link
              to={"articles"}
              className="text-lg cursor-pointer font-semibold text-blue-600 border-b-2 border-blue-600 pb-1"
            >
              View All
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
}
